@tailwind base;

html {
  @apply text-slate-800 dark:text-slate-100 bg-slate-100 dark:bg-slate-800;
}

@tailwind components;

.logo {
  @apply dark:fill-slate-300;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-reverse {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

@tailwind utilities;